<template>
  <div class="draw-winners-display">
    <h1
      class="mb-3"
      :style="{ color: '#202020', fontFamily: welcomeFont }"
    >
      {{ prizeName }}
    </h1>

    <div class="d-flex align-items-center justify-content-center">
      <span
        class="material-icons-outlined"
        :style="{ color: 'goldenrod', fontSize: '1.5em' }"
      >
        star
      </span>

      <h2
        class="mx-2"
        :style="{ fontFamily: welcomeFont, fontSize: '1.95em' }"
      >
        Ganhadores
      </h2>

      <span
        class="material-icons-outlined"
        :style="{ color: 'goldenrod', fontSize: '1.5em' }"
      >
        star
      </span>
    </div>

    <vs-divider />

    <div class="draw-winners-display__content">
      <div
        v-for="winner in winners"
        :key="winner.nome"
      >
        <vs-card class="bg-transparent p-0">
          <div class="d-flex flex-column">
            <!-- Name -->
            <h5
              class="mb-1"
              :style="{ color: '#202020', fontFamily: welcomeFont, fontSize: '1.5em' }"
            >
              {{ capitalizeAllWords(winner.nome) }}
            </h5>

            <!-- Document -->
            <div
              v-if="winner.cpf"
              class="contact-info-line"
            >
              <i class="mdi mdi-card-account-details-outline mr-2" />

              <span
                class="text-muted"
                :style="{ fontSize: '1.25em' }"
              >
                {{ winner.cpf }}
              </span>
            </div>
          </div>
        </vs-card>
      </div>
    </div>

    <vs-divider />
  </div>
</template>

<script>
/* Helpers */
import capitalizeAllWords from '@/helpers/capitalizeAllWords';

export default {
  name: 'DrawWinnersDisplay',
  props: {
    prizeName: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    winners: [],
  }),
  computed: {
    welcomeFont() {
      return this.$store.state.configBox.fontFamilyWelcomeMsg;
    },
    holdList() {
      return this.$store.state.holdList;
    },
  },
  created() {
    if (this.holdList.length > 0) {
      this.winners = this.holdList;

      this.$store.commit('RESET_HOLDLIST');
    }
  },
  methods: {
    /* Helpers */
    capitalizeAllWords(sentence) {
      if (sentence) {
        return capitalizeAllWords(sentence);
      }
    },
  },
};
</script>

<style lang="scss">
.draw-winners-display {
  align-items: center;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;

  @media only screen and (max-width: 1140px) {
    margin-top: 100px;
  }
}

.draw-winners-display__content {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  height: 100%;
  width: 70%;
}

.bg-transparent {
  border-radius: 0.3rem;
  background-color: transparent;
  background: transparent;
  box-shadow: none;
  margin: 0;

  .vs-card--content {
    background-color: transparent;
    background: transparent;
    box-shadow: none;
    margin: 0;
    border-radius: 0.3rem;
  }
}
</style>
