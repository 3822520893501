<template>
  <vs-card>
    <header
      slot="header"
      class="d-flex align-items-center justify-content-between"
    >
      <h3>Meus Sorteios</h3>

      <div
        class="d-flex align-items-center justify-content-between"
        :style="{ gap: '1rem' }"
      >
        <vs-input
          v-model="search"
          placeholder="Pesquisar"
          icon="search"
          icon-after
        />

        <vs-button
          icon="add"
          @click="goToCreateDraw"
        >
          Novo Sorteio
        </vs-button>
      </div>
    </header>

    <main>
      <vs-table
        v-if="filteredPrizeDraws.length > 0"
        v-model="selectedDraw"
        striped
        :data="filteredPrizeDraws"
        @selected="goToEditDraw"
      >
        <template slot="thead">
          <vs-th>
            Sorteio
          </vs-th>

          <vs-th sort-key="prizeDate">
            Data
          </vs-th>

          <vs-th sort-key="prizeTime">
            Horário
          </vs-th>

          <vs-th>
            Início das Inscrições
          </vs-th>

          <vs-th>
            Fim das Inscrições
          </vs-th>

          <vs-th>
            Participantes
          </vs-th>

          <vs-th>
            Ganhadores
          </vs-th>

          <vs-th>
            Ações
          </vs-th>
        </template>

        <template slot-scope="{ data }">
          <vs-tr
            v-for="prizeDraw in data"
            :key="prizeDraw.drawCode"
            :data="prizeDraw"
          >
            <!-- Prize Project Name -->
            <vs-td>{{ prizeDraw.prizeName }}</vs-td>

            <!-- Prize Draw Date -->
            <vs-td>{{ prizeDraw.prizeDate }}</vs-td>

            <!-- Prize Draw Time -->
            <vs-td>{{ prizeDraw.prizeTime }}</vs-td>

            <!-- Registration Start -->
            <td>
              <span v-if="prizeDraw.prizeSignInitDate.length > 0">
                {{ prizeDraw.prizeSignInitDate }} às {{ prizeDraw.prizeSignInitTime }}
              </span>
              <span
                v-else
                class="text-muted"
              >
                N/I
              </span>
            </td>

            <!-- Registration End -->
            <td>
              <span v-if="prizeDraw.prizeSignEndDate.length > 0">
                {{ prizeDraw.prizeSignEndDate }} às {{ prizeDraw.prizeSignEndTime }}
              </span>
              <span
                v-else
                class="text-muted"
              >
                N/I
              </span>
            </td>

            <!-- Participants -->
            <vs-td>
              <!-- View Participants -->
              <vs-button
                class="c-draw-button font-weigth-bold"
                :disabled="prizeDraw.participantsQty === 0"
                icon="format_list_bulleted"
                type="flat"
                @click.stop="() => goToParticipants(prizeDraw.drawCode, prizeDraw.prizeName)"
              >
                <span class="text-nowrap">
                  Ver Lista <small>({{ prizeDraw.participantsQty }})</small>
                </span>
              </vs-button>
            </vs-td>

            <!-- Results -->
            <vs-td>
              <!-- View Winners -->
              <vs-button
                class="c-draw-button font-weigth-bold"
                :disabled="prizeDraw.winners.nRegisters === 0"
                icon="format_list_bulleted"
                type="flat"
                @click.stop="() => goToWinners(prizeDraw.prizeName, prizeDraw.winners.ids)"
              >
                <span class="text-nowrap">
                  Ver Lista <small>({{ prizeDraw.prizeQty }})</small>
                </span>
              </vs-button>
            </vs-td>

            <!-- Actions -->
            <vs-td>
              <div
                class="d-flex align-items-center justify-content-between"
                :style="{ gap: '0.25rem' }"
              >
                <template v-if="!prizeDraw.resultPublished">
                  <!-- Messages -->
                  <vs-tooltip
                    text="Mensagens"
                    position="bottom"
                  >
                    <vs-button
                      icon="email"
                      radius
                      @click.stop="setSelectedPrize(prizeDraw.prizeCode); openMessagesPopup();"
                    />

                    <vs-popup
                      :active.sync="messagesPopup"
                      title="Mensagens"
                    >
                      <DrawMessages
                        :selected-prize="selectedPrize"
                        @go-to-edit-prize="goToEditPrize"
                      />
                    </vs-popup>
                  </vs-tooltip>

                  <!-- Draw Winners -->
                  <vs-tooltip
                    :text="prizeDraw.winners.nRegisters > 0 ? 'Sortear Novamente' : 'Sortear'"
                    position="bottom"
                  >
                    <vs-button
                      icon="casino"
                      radius
                      @click.stop="selectDraw(prizeDraw); openDrawPopup();"
                    />

                    <vs-popup
                      :active.sync="drawPopup"
                      class="draw-winners-popup"
                      fullscreen
                      title=""
                    >
                      <div class="draw-winners-popup__content">
                        <!-- Draw Loading -->
                        <template v-if="showLoading && !showWinners">
                          <div class="image-wrapper">
                            <img src="./assets/prize-wheel.gif">
                          </div>

                          <h3>
                            <span class="c-draw-text-shadow">
                              {{ `Sorteando ` }}

                              <span
                                class="prize-qty mx-1"
                                :style="{
                                  color: colorPrimary,
                                  textShadow: `
                                  0 0 20px ${colorSecondary},
                                  0 0 30px ${colorSecondary},
                                  0 0 40px ${colorSecondary}
                                `,
                                }"
                              >
                                {{ selectedDraw.prizeQty }}
                              </span>

                              {{ ` ganhadores...` }}
                            </span>
                          </h3>
                        </template>

                        <!-- Winners -->
                        <template v-else-if="showWinners && !showLoading">
                          <DrawWinnersDisplay :prize-name="selectedDraw.prizeName" />
                        </template>

                        <!-- Start Draw -->
                        <template v-else>
                          <h1 :style="{ fontFamily: welcomeFont }">
                            {{ selectedDraw.prizeName }}
                          </h1>

                          <div class="image-wrapper">
                            <img src="./assets/prize-wheel-static.gif">
                          </div>

                          <vs-button
                            class="c-draw-vs-button mt-3"
                            size="large"
                            @click="drawWinners"
                          >
                            Iniciar Sorteio
                          </vs-button>
                        </template>
                      </div>

                      <footer class="draw-winners-popup__footer">
                        <span>Powered by:</span>

                        <div class="logo-wrapper">
                          <img src="../../../../assets/images/logo/logo.png">
                        </div>
                      </footer>
                    </vs-popup>
                  </vs-tooltip>

                  <!-- Publish -->
                  <vs-tooltip
                    text="Publicar Resultado"
                    position="bottom"
                  >
                    <vs-button
                      :disabled="prizeDraw.winners.nRegisters === 0"
                      icon="publish"
                      radius
                      @click.stop="selectDraw(prizeDraw); confirmPublishDialog()"
                    />
                  </vs-tooltip>

                  <!-- Delete Draw -->
                  <vs-tooltip
                    text="Deletar Sorteio"
                    position="bottom"
                  >
                    <vs-button
                      color="danger"
                      :disabled="prizeDraw.participantsQty > 0"
                      icon="delete"
                      radius
                      size="small"
                      type="flat"
                      @click.stop="selectDraw(prizeDraw); confirmDeleteDialog();"
                    />
                  </vs-tooltip>
                </template>
                <div
                  v-else
                  class="text-center w-100"
                >
                  <strong :style="{ fontSize: '13.5px' }">
                    Resultado Publicado
                  </strong>

                  <vs-divider class="m-0">
                    {{ prizeDraw.publishDate }}
                  </vs-divider>
                </div>
              </div>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
      <span v-else>
        Nenhum sorteio para mostrar.
      </span>
    </main>
  </vs-card>
</template>

<script>
/* Components */
import DrawMessages from './components/DrawMessages.vue';
import DrawWinnersDisplay from './components/DrawWinnersDisplay.vue';

/* Services */
import Prize from '@/services/prize';

const prizeService = new Prize();

/* Style */
import './assets/index.scss';

export default {
  name: 'PrizeDrawsView',
  components: {
    DrawMessages,
    DrawWinnersDisplay,
  },
  data: () => ({
    prizeDraws: [],
    prizeProjects: [],
    selectedDraw: {},
    selectedPrize: {},
    search: '',
    showLoading: false,
    showWinners: false,
    /* Popups */
    drawPopup: false,
    messagesPopup: false,
  }),
  computed: {
    /* Store */
    colorPrimary() {
      return this.$store.state.configBox.cor1;
    },
    colorSecondary() {
      return this.$store.state.configBox.cor2;
    },
    welcomeFont() {
      return this.$store.state.configBox.fontFamilyWelcomeMsg;
    },
    ownerId() {
      return this.$store.state.ownerID;
    },
    token() {
      const token = this.$store.state.accessToken.split('&');
      const res = token[0].replace('tk=', '');
      return res;
    },
    /* Prize Draws */
    filteredPrizeDraws() {
      if (this.search.length === 0) return this.prizeDraws;

      let filtered = [...this.prizeDraws];

      filtered = filtered.filter((pp) => {
        const searchLowercase = this.search.toLowerCase();
        const prizeNameLowercase = pp.prizeName.toLowerCase();
        return prizeNameLowercase.includes(searchLowercase);
      });

      return filtered;
    },
  },
  watch: {
    // After closing draw popup
    drawPopup(value) {
      if (!value) {
        this.$store.commit('RESET_HOLDLIST');
        this.showLoading = false;
        this.showWinners = false;

        // Get updated draws
        if (this.prizeDraws.length > 0) this.getDraws();
      }
    },
  },
  mounted() {
    this.getDraws();
    this.getPrizeProjects();
  },
  methods: {
    /* API */
    deleteDraw() {
      prizeService.deletePrizeDraw(
        this.selectedDraw.drawCode,
        this.token,
      )
        .then(() => this.getDraws())
        .catch((err) => this.handleErr(err));
    },
    drawWinners() {
      this.showLoading = true;

      prizeService.drawWinners(
        this.ownerId,
        this.selectedDraw.drawCode,
        this.token,
      )
        .then(() => {
          this.getWinnersAndLoad(this.selectedDraw.drawCode);
        })
        .catch((err) => this.handleErr(err));
    },
    getDraws() {
      this.$store.dispatch('set_isLoadingActive', true);

      prizeService.getDraws(this.ownerId, this.token)
        .then(async (resp) => {
          const promises = resp.map(async (draw) => {
            const winners = await this.getWinners(draw.drawCode);

            return {
              ...draw,
              winners,
            };
          });

          const draws = await Promise.all(promises)
            .finally(() => this.$store.dispatch('set_isLoadingActive', false));

          this.prizeDraws = draws;
        })
        .catch((err) => this.handleErr(err));
    },
    getPrizeProjects() {
      this.$store.dispatch('set_isLoadingActive', true);

      prizeService.getPrizeProjects(
        this.ownerId,
        this.token,
      )
        .then((resp) => (this.prizeProjects = resp))
        .catch((err) => this.handleErr(err))
        .finally(() => this.$store.dispatch('set_isLoadingActive', false));
    },
    async getWinners(drawCode) {
      const winners = await prizeService.getWinners(
        this.ownerId,
        drawCode,
        this.token,
      );

      return winners;
    },
    async getWinnersAndLoad(drawCode) {
      const winners = await prizeService.getWinners(
        this.ownerId,
        drawCode,
        this.token,
      );

      this.$store.commit('SET_HOLDLIST', winners.ids);

      // Wait some time for the prize wheel to animate
      const ms = 5000;

      this.$store.dispatch('set_isLoadingActive', true);

      setTimeout(() => {
        this.$store.dispatch('set_isLoadingActive', false);
        this.showLoading = false;
        this.showWinners = true;
      }, ms);
    },
    publishDraw() {
      this.$store.dispatch('set_isLoadingActive', true);

      prizeService.publishDraw(
        this.selectedDraw.drawCode,
        this.token,
      )
        .then(() => {
          this.publishedAlert();
          this.getDraws();
        })
        .catch((err) => this.handleErr(err))
        .finally(() => this.$store.dispatch('set_isLoadingActive', false));
    },
    /* Helpers */
    handleErr(err) {
      console.error(err);

      this.$vs.notify({
        title: 'Erro',
        color: 'danger',
        text: err,
      });
    },
    selectDraw(selected) {
      this.selectedDraw = selected;
    },
    setSelectedPrize(prizeCode) {
      const found = this.prizeProjects.find((pp) => {
        return pp.prizeCode === prizeCode;
      });

      this.selectedPrize = found;
    },
    /* Popups */
    confirmDeleteDialog() {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Excluir Sorteio',
        text: 'Tem certeza que deseja excluir este sorteio?',
        acceptText: 'Deletar',
        cancelText: 'Cancelar',
        accept: () => this.deleteDraw(),
      });
    },
    confirmPublishDialog() {
      this.$vs.dialog({
        type: 'confirm',
        color: 'primary',
        title: 'Publicar Sorteio',
        text: 'Depois de publicado, não será mais possível alterar ou repetir esse sorteio. Deseja continuar?',
        acceptText: 'Publicar',
        cancelText: 'Cancelar',
        accept: () => this.publishDraw(),
      });
    },
    openDrawPopup() {
      this.drawPopup = true;
    },
    openMessagesPopup() {
      this.messagesPopup = true;
    },
    /* Alerts */
    publishedAlert() {
      this.$vs.notify({
        time: 4000,
        color: 'primary',
        title: 'Sorteio Publicado',
        text: 'Sorteio publicado com sucesso!',
      });
    },
    /* Router */
    goToCreateDraw() {
      this.$router.push('/sorteios/criar');
    },
    goToEditDraw() {
      this.$router.push({
        name: 'Editar Sorteio',
        params: { selectedDraw: this.selectedDraw },
      });
    },
    goToEditPrize() {
      this.$router.push({
        name: 'Editar Grupo de Sorteio',
        params: { selectedPrize: this.selectedPrize },
      });
    },
    goToParticipants(drawCode, prizeName) {
      this.$router.push({
        name: 'Contatos do Sorteio',
        params: {
          title: 'Participantes',
          drawCode,
          prizeName,
          headers: {
            id: 'surveyUserId',
            name: 'name',
            document: 'cpf',
            phone: 'phone',
            email: 'email',
            delete: 'contact',
          },
        },
      });
    },
    goToWinners(prizeName, ids) {
      this.$store.commit('SET_HOLDLIST', ids);

      this.$router.push({
        name: 'Contatos do Sorteio',
        params: {
          title: 'Ganhadores',
          prizeName,
          headers: {
            id: 'identity',
            name: 'nome',
            document: 'cpf',
            phone: 'phone',
            email: 'email',
            fanview: 'contact',
          },
        },
      });
    },
  },
};
</script>

<style lang="scss">
.c-draw-vs-button.vs-button.large {
  font-size: 15px;
  padding: 13px 22px;
}

.c-draw-text-shadow {
  text-shadow: rgba(125, 125, 125, 0.4) 10px 6px 10px;
}

.c-draw-button {
  .register-count {
    align-items: center;
    display: flex;
    gap: 0.15rem;
  }

  .vs-button-text.vs-button--text {
    align-items: center;
    display: flex;
    gap: 0.35rem;
  }
}

.c-vs-collapse--open {
  .open-item > .vs-collapse-item--content {
    max-height: none !important;
  }
}

/* Draw Winners Popup */
.draw-winners-popup {
  .vs-popup--content {
    align-items: center;
    background: radial-gradient(
      circle,
      rgba(255, 255, 255, 1) 50%,
      rgb(220, 220, 220) 100%
    );
    border-radius: 0 0 0.3rem 0.3rem !important;
    display: flex;
    flex-flow: column nowrap;
    height: 100%;
    justify-content: center;
    margin: 0 !important;
    max-height: calc(100vh - 70px) !important;
    width: 100%;
  }

  .draw-winners-popup__content {
    align-items: center;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    text-align: center;
    height: 100%;
    width: 100%;

    .image-wrapper {
      height: 180px;

      img {
        height: 100%;
      }
    }
  }

  .draw-winners-popup__footer {
    align-items: center;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    gap: 1rem;
    padding-bottom: 0.75rem;

    span {
      font-size: 0.875rem;
      line-height: 1.25rem;
    }

    .logo-wrapper {
      height: 25px;

      img {
        height: 100%;
      }
    }
  }
}
</style>
