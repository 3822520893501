<template>
  <vs-list class="draw-notifications__content">
    <header class="d-flex align-items-center justify-content-between w-100">
      <span class="text-secondary">
        Ative notificações manualmente ou envie uma campanha.
      </span>

      <div class="d-flex align-items-center">
        <i class="mdi mdi-dots-vertical mr-2" />

        <vs-button
          type="flat"
          @click="goToEditPrize"
        >
          Gerenciar Mensagens
        </vs-button>
      </div>
    </header>

    <!-- Invite Participants -->
    <vs-list-header title="Convite para o Sorteio" />

    <vs-list-item>
      <SelectTemplate
        :templates="emailTemplates"
        template-type="email"
        :readonly="true"
        :value="selectedPrize.inviteTemplate"
      />

      <vs-button
        class="my-3"
        :disabled="!selectedPrize.inviteTemplate"
        icon="email"
        type="border"
        @click="() => setCampaign('Convite', 'email', selectedPrize.inviteTemplate)"
      >
        Enviar Campanha
      </vs-button>
    </vs-list-item>

    <!-- Announce Prize Draw -->
    <vs-list-header title="Anúncio do Sorteio" />

    <vs-list-item>
      <SelectTemplate
        :templates="emailTemplates"
        template-type="email"
        :readonly="true"
        :value="selectedPrize.announcementTemplate"
      />

      <vs-button
        class="my-3"
        :disabled="!selectedPrize.announcementTemplate"
        icon="email"
        type="border"
        @click="() => setCampaign('Anúncio', 'email', selectedPrize.announcementTemplate)"
      >
        Enviar Campanha
      </vs-button>
    </vs-list-item>

    <!-- Notify Winners -->
    <vs-list-header title="Resultados" />

    <vs-list-item>
      <div class="d-flex align-items-start mb-3">
        <div>
          <SelectTemplate
            :templates="emailTemplates"
            template-type="email"
            :readonly="true"
            :value="selectedPrize.resultsEmailTemplate"
          />

          <vs-button
            class="mt-3"
            :disabled="!selectedPrize.resultsEmailTemplate"
            icon="email"
            type="border"
            @click="() => setCampaign('Resultados', 'email', selectedPrize.resultsEmailTemplate)"
          >
            Enviar Campanha
          </vs-button>
        </div>

        <div class="ml-5">
          <SelectTemplate
            :templates="smsTemplates"
            template-type="sms"
            :readonly="true"
            :value="selectedPrize.resultsMessageTemplate"
          />

          <vs-button
            class="mt-3"
            :disabled="!selectedPrize.resultsMessageTemplate"
            icon="message"
            type="border"
            @click="() => setCampaign('Resultados', 'sms', selectedPrize.resultsMessageTemplate)"
          >
            Enviar Campanha
          </vs-button>
        </div>
      </div>
    </vs-list-item>
  </vs-list>
</template>

<script>
/* Components */
import SelectTemplate from './SelectTemplate.vue';

/* Services */
import Campaign from '@/services/campaign';
import Template from '@/services/template';

const campaignService = new Campaign();
const templateService = new Template();

/* Style */
import '../assets/index.scss';

export default {
  name: 'DrawMessages',
  components: { SelectTemplate },
  props: {
    selectedPrize: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    emailTemplates: [],
    messageTemplates: [],
  }),
  computed: {
    /* Store */
    ownerId() {
      return this.$store.state.ownerID;
    },
    /* Data */
    smsTemplates() {
      if (this.messageTemplates.length === 0) {
        return [];
      } else {
        let filtered = [...this.messageTemplates];

        filtered = filtered.filter((template) => {
          return template.contents.some((c) => {
            return c.type.toLowerCase() === 'sms';
          });
        });

        return filtered;
      }
    },
    // whatsappTemplates() {
    //   if (this.messageTemplates.length === 0) {
    //     return [];
    //   } else {
    //     let filtered = [...this.messageTemplates];

    //     filtered = filtered.filter((template) => {
    //       return template.contents.some((c) => {
    //         return c.type.toLowerCase() === 'whatsapp';
    //       });
    //     });

    //     return filtered;
    //   }
    // },
  },
  created() {
    this.getEmailTemplates();
    this.getMessageTemplates();
  },
  methods: {
    /* API */
    getEmailTemplates() {
      this.$store.dispatch('set_isLoadingActive', true);

      templateService.getTemplates(this.ownerId)
        .then((res) => this.emailTemplates = res)
        .finally(() => this.$store.dispatch('set_isLoadingActive', false));
    },
    getMessageTemplates() {
      this.$store.dispatch('set_isLoadingActive', true);

      campaignService.getMessageTemplates(this.ownerId)
        .then((res) => this.messageTemplates = res)
        .finally(() => this.$store.dispatch('set_isLoadingActive', false));
    },
    /* Helpers */
    getTemplateInfo(templateId) {
      const found = this.emailTemplates.find((t) => t.id === templateId);
      if (found) return found;
    },
    goToEditPrize() {
      this.$emit('go-to-edit-prize');
    },
    setCampaign(subject, campaignType, template) {
      let campaignTypeConfig = {};

      if (campaignType === 'email') {
        campaignTypeConfig = {
          tipo: 1,
          config: {
            subject: `${subject}: ${this.selectedPrize.prizeName}`,
            emailRemetente: '',
            templateType: 1,
            fileID: template,
            templateUrl: this.getTemplateInfo(template).urlPreview,
            templateName: this.getTemplateInfo(template).value,
          },
        };
      }

      if (campaignType === 'sms') {
        campaignTypeConfig = {
          tipo: 2,
          config: {
            templateCode: template,
            msg: '',
          },
        };
      }

      if (campaignType === 'whatsapp') {
        campaignTypeConfig = {
          tipo: 3,
          config: {
            templateCode: template,
            msg: '',
          },
        };
      }

      const campaignInfo = {
        campanha: {
          nome: `${subject}: ${this.selectedPrize.prizeName}`,
          filtro: this.selectedPrize.filterId,
        },
        etapas: [campaignTypeConfig],
      };

      this.$store.commit('SET_campInfo', campaignInfo);

      const wait = 100;
      setTimeout(() => this.goToCampaign(), wait);
    },
    /* Router */
    goToCampaign() {
      this.$router.push('/campanhas/edit');
    },
  },
};
</script>

<style lang="scss">
.draw-notifications__content {
  .vs-list--slot {
    width: 100%;
  }

  .vs-list--title {
    font-size: medium;
  }

  .vs-list--item {
    background-color: #fbfbfb;
  }
}
</style>
